import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, DatabaseReference } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyClTsQtr6BDKsNFrbEpbH5I3N3otyhZI5U",
  authDomain: "true-masters-52014151.firebaseapp.com",
  databaseURL: "https://true-masters-52014151-default-rtdb.firebaseio.com",
  projectId: "true-masters-52014151",
  storageBucket: "true-masters-52014151.appspot.com",
  messagingSenderId: "408514364093",
  appId: "1:408514364093:web:a0b0096f787b4532646dd4",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getDatabase(app);

const dbRef: DatabaseReference = ref(db);

export { app, auth, db, dbRef };
