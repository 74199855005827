import React, { useContext, useEffect, useState } from "react";
import { userDataContext } from "../context/userDataContext";
import { auth, dbRef } from "../firebase/firebase";
import { get, child } from "firebase/database";
import { useNavigate } from "react-router-dom";
import UserData from "./UserData";
import LoadingView from "../views/LoadingView";
import DeleteUser from "./DeleteUser";

export default function User() {
  const navigate = useNavigate();
  const user = auth.currentUser;

  const { setUserDataContext } = useContext(userDataContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user == null) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = () => {
    setLoading(true);
    get(child(dbRef, `users/${user?.uid}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUserDataContext(snapshot.val());
        } else {
          console.log("No data available");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user != null) getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <LoadingView isLoading={loading}>
        <UserData></UserData>
        <DeleteUser></DeleteUser>
      </LoadingView>
    </div>
  );
}
