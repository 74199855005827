import React, { useState } from "react";
import { authSingIn } from "../firebase/auth";
import { useNavigate } from "react-router-dom";
import LoadingView from "../views/LoadingView";
import ErrorPopup from "./ErrorPopup";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    try {
      await authSingIn(email, password);
      navigate("/user");
    } catch (error: any) {
      console.error(error);
      setError(error.code);
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div>
        <LoadingView isLoading={loading}>
          {/* <img
            src="/assets/logo.png"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          /> */}

          <div className="logo-container">
            <img
              src="/assets/logo.png"
              alt="Descripción de la imagen"
              className="logo"
            />
          </div>

          <h2>LOGIN</h2>
          <form onSubmit={handleLogin}>
            <div>
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br />

            <button type="submit">Login</button>
          </form>
          {error && <ErrorPopup error={error} />}
        </LoadingView>
      </div>
    </div>
  );
};

export default Login;
