import React, { useContext, useState } from "react";
import { auth, db } from "../firebase/firebase";
import { ref, remove } from "firebase/database";
import { userDataContext } from "../context/userDataContext";
import LoadingView from "../views/LoadingView";
import "./DeleteUser.css";
import { Link, useNavigate } from "react-router-dom";
import ErrorPopup from "./ErrorPopup";
import ConfirmationDialog from "./ConfirmationDialog";

const DeleteUser = () => {
  const { userData, setUserDataContext } = useContext(userDataContext);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();

  const user = auth.currentUser;

  const handleDeleteAccount = () => {
    setShowConfirmation(false);
    setTimeout(() => {
      setShowConfirmation(true);
    }, 0);
  };

  const handleConfirmation = (result: boolean) => {
    if (result) {
      borrarCuenta();
    }

    setShowConfirmation(false);
  };

  const borrarCuenta = () => {
    if (user == null) {
      navigate("/login");
      return;
    }
    // Mostrar un cuadro de diálogo de confirmación
    setLoading(true);

    remove(ref(db, "users/" + user.uid))
      .then(() => {
        console.log("Datos asociados eliminados correctamente.");

        remove(ref(db, "usernames/" + userData?.username))
          .then(() => {
            console.log("Username eliminado correctamente.");

            user
              .delete()
              .then(() => {
                console.log("Cuenta eliminada correctamente.");
                dataDeleted();
              })
              .catch((error) => {
                console.error("Error al borrar los datos:", error.message);
                setError(error.message);
                setLoading(false);
              });
          })
          .catch((error) => {
            console.error("Error al borrar username:", error.message);
          });
      })
      .catch((error) => {
        console.error("Error al eliminar los datos asociados:", error.message);
      });
  };

  const dataDeleted = () => {
    setLoading(false);

    setUserDataContext(null);

    setIsDeleted(true);
  };

  return (
    <div
      className={
        isDeleted
          ? "delete-account-container deleted"
          : "delete-account-container"
      }
    >
      <LoadingView isLoading={loading}>
        <div className="delete-account-container-inner">
          {!isDeleted ? (
            <>
              <h2>Delete Account</h2>
              <button onClick={handleDeleteAccount}>Delete</button>
            </>
          ) : (
            <>
              <h2>Account deleted successfully</h2>
              <Link to="/login">
                <button>Go to login</button>
              </Link>
            </>
          )}
        </div>
      </LoadingView>

      {showConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to delete your account?"
          subMessage="This action cannot be undone"
          onConfirm={handleConfirmation}
        />
      )}

      {error && <ErrorPopup error={error} />}
    </div>
  );
};

export default DeleteUser;
