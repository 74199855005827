import React, { useContext } from "react";
import { userDataContext } from "../context/userDataContext";
import "./UserData.css";

const UserData = () => {
  const { userData } = useContext(userDataContext);

  console.log(userData);

  const formatValue = (value: any): string => {
    if (value !== 0) return "✔";
    else return "✘";
  };

  const formatName = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  function formatTime(time: number): string {
    const minutes = time / 60;
    return minutes.toFixed(0) + " minutes";
  }

  return (
    <div>
      {userData == null ? (
        <div className="user-no-data">No data available</div>
      ) : (
        <div className="user-data-container">
          <div className="ListDiv">
            <h3>User Data</h3>
            <ul>
              <li>Username: {userData.username}</li>
              <li>Crystals: {userData.crystals}</li>
              <li>Trophies: {userData.trophies}</li>
              <li>Survival Record: {userData.survivalRecord}</li>
              <li>Played Time: {formatTime(userData.playedTime)}</li>
              <li>Patreon Benefits: {formatValue(userData.tmPremium)}</li>
            </ul>
          </div>
          <div className="ListDiv">
            <h3>Unlockable Characters</h3>
            <ul>
              {Object.entries(userData.characters).map(([character, value]) => (
                <li key={character}>
                  {formatName(character)}: {formatValue(value)}
                </li>
              ))}
            </ul>
          </div>
          <div className="ListDiv">
            <h3>Stages</h3>
            <ul>
              {Object.entries(userData.stages).map(([stage, value]) => (
                <li key={stage}>
                  {formatName(stage)}: {formatValue(value)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserData;
