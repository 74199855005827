import React from "react";
import "./ConfirmationDialog.css";

interface ConfirmationDialogProps {
  message: string;
  subMessage?: string;
  onConfirm: (confirmed: boolean) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  subMessage,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm(true);
  };

  const handleCancel = () => {
    onConfirm(false);
  };

  return (
    <div className="confirmation-dialog-container">
      <h4>{message}</h4>
      <p>{subMessage}</p>
      <div className="confirm-buttons">
        <button onClick={handleConfirm}>Confirm</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
