import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import User from "./components/User";
import Login from "./components/Login";
import { UserContextProvider } from "./context/userDataContext";

function App() {
  return (
    <div className="App">
      <Router>
        <UserContextProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user" element={<User />} />
          </Routes>
        </UserContextProvider>
      </Router>
    </div>
  );
}

export default App;
