import React, { createContext, useState, ReactNode } from "react";

// Definimos la forma del estado de userData
interface UserData {
  characters: Record<string, number>;
  crystals: number;
  lastDevice: string;
  lastSessionDate: number;
  onlineCode: string;
  playedTime: number;
  playingMode: string;
  stages: Record<string, number>;
  survivalRecord: number;
  tmPremium: number;
  trophies: number;
  userID: string;
  username: string;
}

// Creamos un contexto con el tipo UserData | null
const userDataContext = createContext<{
  userData: UserData | null;
  setUserDataContext: (newUser: UserData | null) => void;
}>({
  userData: null,
  setUserDataContext: () => {}, // Función vacía por defecto
});

// Definimos las propiedades del componente UserContextProvider
interface UserContextProviderProps {
  children: ReactNode;
}

// Componente que proporciona el contexto
const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData | null>(null);

  // Función para actualizar el valor del contexto
  const setUserDataContext = (newUser: UserData | null) => {
    setUserData(newUser);
  };

  return (
    <userDataContext.Provider value={{ userData, setUserDataContext }}>
      {children}
    </userDataContext.Provider>
  );
};

export { userDataContext, UserContextProvider };
