import {
  ActionCodeSettings,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { auth } from "./firebase";

export const authCreateUser = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const authSingIn = (email: string, password: string) => {
  console.log(auth);
  return signInWithEmailAndPassword(auth, email, password);
};

export const authSingOut = () => {
  return auth.signOut();
};

export const authPasswordReset = (email: string) => {
  const actionCodeSettings: ActionCodeSettings = {
    url: "https://www.example.com", // Url que redirige al usuario después de restablecer la contraseña
    handleCodeInApp: true, // Indica si se manejará el código de acción en la aplicación
  };
  return sendSignInLinkToEmail(auth, email, actionCodeSettings);
};

export const authPasswordChange = (newPassword: string) => {
  if (auth.currentUser !== null)
    return updatePassword(auth.currentUser, newPassword);
};

export const authEmailVerification = () => {
  if (auth.currentUser !== null)
    return sendEmailVerification(auth.currentUser, {
      url: `${window.location.origin}/home`,
    });
};

export const authDeleteAccount = (newPassword: string) => {
  if (auth.currentUser !== null)
    return updatePassword(auth.currentUser, newPassword);
};
