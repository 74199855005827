import React, { ReactNode } from "react";
import Loading from "../components/Loading";

interface LoadingViewProps {
  isLoading: boolean;
  children: ReactNode;
}

const LoadingView = ({ isLoading, children }: LoadingViewProps) => {
  return <div>{isLoading ? <Loading /> : children}</div>;
};

export default LoadingView;
