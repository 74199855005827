import React, { useState, useEffect } from "react";
import "./ErrorPopup.css";

interface ErrorPopupProps {
  error: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ error }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Ocultar el popup después de 3 segundos
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`error-popup ${isVisible ? "show" : "hide"}`}>
      <p>{error}</p>
    </div>
  );
};

export default ErrorPopup;
